/* eslint-disable camelcase */
<template>
<div id="query_robot">
    <div class="robot-management">
    <strong style="font-size:13px">机器人类别</strong>
    <div class="buttons">
      <el-input
      v-model="robot_info"
      placeholder="请输入分类名称"
      style="width: 16vw;"
      clearable
      >
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
        <el-select v-model.trim="MissionTemplateForm" style="width: 16vw;"
         placeholder="请选择机器人状态" clearable>
          <el-option
            v-for="state in StatusOptions"
            :key="state.key"
            :value="state.key"
            :label="state.display_name"
          ></el-option>
        </el-select>
      <el-button @click="querySearchAsync()" type="primary" icon="el-icon-search" size="mini">查询</el-button>
      <el-button @click="tableRowClassName1()" size="mini" type="primary" >重置</el-button>
      <el-button type="primary"  @click="tableRowClassName()" size="mini">新增分类</el-button>
      </div>
    </div>
    <single
    :Visible="dialogTableVisible"
    @cancel="visible_false"
    >
    </single>
    <div class="table-container">
      <el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :stripe=true :highlight-current-row=true :row-style="{height: '0.1'}" :cell-style="{padding: '4px'}">
        <el-table-column label="序号" width="100px" align="center">
        <template slot-scope="scope">
          {{ scope.$index + (currentPage - 1) * pageSize + 1 }}
        </template>
        </el-table-column>
        <el-table-column prop="id" label="分类ID" align="center"></el-table-column>
        <el-table-column prop="typename" label="分类名称" align="center"></el-table-column>
        <el-table-column prop="createdby" label="创建人" align="center"></el-table-column>
        <!-- <el-table-column prop="robot_area" label="机器人地区" ></el-table-column> -->
        <el-table-column prop="createdon" label="创建时间" width="180px" align="center" ></el-table-column>

        <el-table-column label="状态" align="center" >
          <template slot-scope="scope">
            <el-tag type="success"  v-if="scope.row.active==1">启用</el-tag>
            <el-tag type="danger"  v-if="scope.row.active==0">停用</el-tag>
            <!-- <p v-if="scope.row.active==1" type="success">启用</p>
            <p v-if="scope.row.active==0">停用</p> -->
            </template>
        </el-table-column>
        <el-table-column prop="robot_type" label="操作" align="center">
          <template slot-scope="scope">
          <!-- <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
            <el-button  v-if="scope.row.active==1" @click="switchChange(scope.row.id)" type="text" round>停用</el-button>
            <el-button v-if="scope.row.active==0" @click="switchChange(scope.row.id)" type="text" round>启用</el-button>
    <!--        <el-tag type="success" effect="danger" v-if="scope.row.active==1">停用</el-tag>-->
    <!--        <el-tag  type="dark" effect="dark" v-if="scope.row.active==0">启用</el-tag>-->
            <!-- <el-tag type="danger" effect="dark" v-if="scope.row.userxu==3">删除</el-tag> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
  <div style="text-align: right; padding: 30px">
    <el-pagination
      background
      @current-change="handleCurrentChange"
      layout="prev, pager, next, jumper"
      :total="tableData.length"
      :page-size="pageSize"
    >
  </el-pagination>
  </div>
</div>
</template>

<script>
// eslint-disable-next-line camelcase
import single from './single_add_robot.vue'
import { Message } from 'element-ui'
// import single_add_robot from '@/views/Robot_Management/single_add_robot'
// import router from 'router'
//   const StatusOptions = [
//   { key: '0', display_name: '启用' },
//   { key: '1', display_name: '禁用' }
// ]
export default {
  name: 'query_robot',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    single
  },
  // created () {
  //   // 获取分类列表
  //   this.open()
  //   this.newdata = []
  // },

  data () {
    return {
      tableData: [],
      dialogTableVisible: false,
      total: 0,
      currentPage: 1,
      pageSize: 10,
      robot_info: '',
      MissionTemplateForm: '',
      StatusOptions: [
        { key: 1, display_name: '启用' },
        { key: 0, display_name: '停用' }
      ]
    }
  },
  // watch: {
  //   dialogTableVisible (current, prev) {
  //     // this.dialogTableVisible = false
  //     console.log(current, prev)
  //     this.dialogTableVisible = false
  //   }
  // },
  created () {
    // const id = localStorage.use_unit
    this.getdata()
  },
  methods: {
    getdata () {
      this.axios.get('/Robot_Management/query_robot/', {
      }).then((res) => {
        // if(res.data)
        // Message.success('登陆成功')
        console.log(res.data.data)
        console.log(res.data)
        this.tableData = res.data.data
        this.total = this.tableData.length
      }).catch((error) => {
        console.log(error)
      })
    },
    handleCurrentChange (val) {
      this.currentPage = val
    },
    tableRowClassName () {
      this.dialogTableVisible = true
    },
    visible_false (ev) {
      this.dialogTableVisible = ev
      // console.log(ev, 'qwqwqwq')
      this.tableRowClassName1()
    },
    // 重置按钮
    tableRowClassName1 () {
      this.MissionTemplateForm = ''
      this.robot_info = ''
      this.axios.get('/Robot_Management/query_robot/', {
      }).then((res) => {
        // if(res.data)
        // Message.success('登陆成功')
        this.tableData = res.data.data
        this.total = this.tableData.length
        console.log(res.data.data)
      })
      // console.log(data, robotinfo)
    },
    // 传入需要操作机器人状态的ID
    switchChange (data) {
      console.log(data)
      this.istag = !this.istag
      this.axios.post('/Robot_Management/modify_state/', {
        data
      }).then((res) => {
        if (res.data.res === true) {
          this.tableData = res.data.data
          Message.success(res.data.message)
          this.getdata()
        } else if (res.data.res === false) {
          Message.warning(res.data.message)
        }
      })
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage
    },
    querySearchAsync () {
      // this.dialogTableVisible = false
      const robotinfo = this.robot_info
      const MissionTemplateForm = this.MissionTemplateForm
      console.log(this.MissionTemplateForm, this.robot_info)
      if (robotinfo !== '' || MissionTemplateForm !== '') {
        this.axios.post('/Robot_Management/robot_info/', {
          robotinfo, MissionTemplateForm
        }).then((res) => {
          this.tableData = res.data.data
          if (res.data.message === '查询失败！') {
            this.tableData = []
          }
          console.log(res.data.message)
        })
      } else {
        this.getdata()
      }
    }
  }
}
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}
.el-divider{
   margin: 1px;
}
#active{
  background:red;
}
#active1{
  background:green ;
}

.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
}
.robot-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.robot-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}
</style>
