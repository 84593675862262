<template>
<div>
  <el-dialog
  width="800px"
  :visible.sync="Visible"
  title="创建分类"
  :close-on-click-modal="false"
  label-width="70px"
  :before-close="handleCancel"  >
    <el-row>
      <el-col >
        <div class="grid-content bg-purple-light" style="background:transparent">
          <el-form
            :model="ruleForm"
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            :rules="rules"
          >

            <table border="0">
              <tr>
                <td>
                  <el-form-item   label="分类名称:" prop="robot_type">
                    <el-input v-model="ruleForm.robot_type" placeholder="请输入分类名称" ></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>
                  <el-form-item label="分类说明:" prop="robot_name">
                    <el-input v-model="ruleForm.robot_name" type="textarea" :rows="2" placeholder="请输入分类说明50个字以内"></el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td><el-button style="height:36px;margin-left: 550px" type="primary"  @click="submitForm('ruleForm')">提交</el-button>
                <el-button style="height:36px;margin-left: 20px" type="info" @click="resetForm('ruleForm')">重置</el-button></td>
              </tr>
            </table>
          </el-form>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple" style="background:transparent"></div>
      </el-col>
    </el-row>
  </el-dialog>
</div>
</template>
<script>
import { Message } from 'element-ui'
export default {
  name: 'single_add_robot',
  props: {
    Visible: Boolean
  },
  data () {
    return {
      options: [
        {
          value: '财务共享',
          label: '财务共享'
        },
        {
          value: '会易通',
          label: '会易通'
        }, {
          value: '党务通',
          label: '党务通'
        }, {
          value: '人力易通',
          label: '人力易通'
        }, {
          value: '审计通',
          label: '审计通'
        }],
      ruleForm: {
        robot_name: '',
        robot_path: '',
        robot_type: '',
        robot_area: localStorage.getItem('yun_user')
      },
      // eslint-disable-next-line vue/no-dupe-keys
      Visible1: false,
      rules: {
        robot_type: [
          { required: true, message: '请输入分类名', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        robot_name: [
          { min: 0, max: 50, message: '最多50个字符', trigger: 'blur' }
        ]
      }
      // aa: false,
      // eslint-disable-next-line vue/no-dupe-keys
    }
  },
  methods: {
    submitForm (formName) {
      const data = this.ruleForm
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.post('/Robot_Management/', {
            data
          })
            .then(res => {
              console.log(res.data)
              if (res.data === 1) {
                Message.success('增加成功')
                console.log(res.data)
                this.$refs[formName].resetFields()
                this.handleCancel()
              } else {
                Message.warning('分类名已存在！')
              }
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          Message.warning('输入信息规则有误')
        }
      })
    },
    handleCancel () {
      this.$emit('cancel', this.Visible1)
    },
    resetForm (formName) {
      console.log('reset!')
      this.ruleForm.robot_type = ''
      this.ruleForm.robot_name = ''

      // this.$refs[formName].resetFields()
      // this.$refs[formName].resetFields()
      // this.$refs.resetFields()
    }
  }
}
</script>

<style scoped>
#sigle_add_robot {
  margin-top: 1px;
}
h5 {
  line-height: 1px;
  color: #0e0d0d;
  font-size: 22px;
}
th {
  border-top: 100cm;

  color: black;
  font-size: 22px;
}

#bitian{
  color: red;
  font-size: 15px;
  height: 2px;
  background: #0a0a0a;
  top: 105px;

  /*top: 1000px;*/
  /*border-right: 3000cm;*/
  /*border-left: 10000px ;*/
}
</style>
